import React, { useRef, useState } from 'react'
import logo from '../assets/uci-logo-main.png'
import { NavLink } from 'react-router-dom'
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import ChatWindow from './chat/ChatWindow';
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';


const Footer = () => {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const menuRef = useRef(null)

    const toggleMenu = () => {
        setShow(!show)
    }

    const handleClose = () => {
        setShow(false)
    }

    const searchOpen = () => {
        setOpen(true)
    }

    const searchClose = () => {
        setOpen(false)
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <div   >
            <div className="pg-footer" >
                <div className="footer" >
                    <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
                        <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
                    </svg>
                    <div className="container">
                    <div className="row mt-5" >
                        <div className="col-lg-4 col-md-4 col-xxl-4" >
                            <img src={logo} className="footer-logo" />
                            <div className='footer-contact-details' >
                                <p className="logo-text " >United Consulting International LTD.</p>
                                {/* <p><FaLocationDot className="arrow" /> 2-4 ARGYLE ROAD, KINGSTON 5</p> */}
                                <p><MdEmail className="arrow" /> info@uciconsult.com  </p>
                                <p><FaPhone className="arrow" /> (876) 239-1727</p>
                            </div>
                            <div className="social-links my-3" >
                                <Link to='https://www.facebook.com/ucicooperate' target='_blank' className="social-icon" >
                                    <FaFacebookF />
                                </Link>
                                <Link className="social-icon" to='https://www.instagram.com/ucicooperate/' target='_blank'>
                                    <FaInstagram />
                                </Link>
                                <Link to='https://www.linkedin.com/company/united-consulting-international-ltd/' target='_blank' className="social-icon" >
                                    <FaLinkedinIn />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-xxl-4'>
                            <h3 className='font' >Quick Links</h3>
                            <ul className="footer-list" >
                                <li  onClick={() => { handleClose(); scrollToTop() }}><Link to='/' className='li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Home</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/aboutUs'  className='li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />About Us</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/allblog'  className='li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Blog</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/gallery'  className='li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Gallery</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/contactUs'  className='li_footer'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Contact Us</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/terms-use' className='li_footer' ><MdOutlineKeyboardDoubleArrowRight className="arrow" />Terms of Use</Link></li>
                                <li onClick={() => { handleClose(); scrollToTop() }}><Link to='/privacy' className='li_footer' ><MdOutlineKeyboardDoubleArrowRight className="arrow" />Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-5 col-md-5 col-xxl-4" >
                            <h3 className='font' >Services</h3>
                            <ul className="footer-list" >
                                {/* <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" />Initial Data-privacy Program Implementaton (IDPI)</NavLink></li>
                                <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" />The Outsourced Data Protection Officer Service (ODPO)</NavLink></li>
                                <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" /> The General Support, Consulting And Advisory Service (GSCA)</NavLink></li>
                                <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" />The Cyber Security & Technical Privacy Services (CSTP)</NavLink></li>
                                <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" />Data Privacy Process Re-Engeneering & Alignment (DPRA)</NavLink></li>
                                <li><NavLink><MdOutlineKeyboardDoubleArrowRight className="arrow" />Organizational & Executive Privacy Training & Development (OPTD)</NavLink></li> */}
                                 <li onClick={() => { handleClose(); scrollToTop() }}><NavLink to='/services'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Data Privacy Services</NavLink></li>
                                 <li onClick={() => { handleClose(); scrollToTop() }}><NavLink to='/Corporate-Compliance'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Corporate Compliance & Business Consultation</NavLink></li>
                                 <li onClick={() => { handleClose(); scrollToTop() }}><NavLink to='/ifrs-services'><MdOutlineKeyboardDoubleArrowRight className="arrow" />Actuarial & IFRS9 Services</NavLink></li>
                            </ul>
                        </div>
                        
                        {/* <div className="col-lg-4" >
                            <h3 className='font' >Socials</h3>
                            <div className="social-links my-3" >
                                <Link to='https://www.facebook.com/ucicooperate' target='_blank' className="social-icon" >
                                    <FaFacebookF />
                                </Link>
                                <Link className="social-icon" to='https://www.instagram.com/ucicooperate/' target='_blank'>
                                  <FaInstagram />
                                </Link>
                                <Link to='https://www.linkedin.com/company/united-consulting-international-ltd/' target='_blank' className="social-icon" >
                                    <FaLinkedinIn />
                                </Link>
                            </div>

                        </div> */}
                    </div>
                    </div>
                    <div className="copyright text-center" >
                        <p>©2024. | United Consulting International Limited | All rights reserved.</p>
                    </div>
                </div>
            </div>
            {/* <ChatWindow /> */}
        </div>
    )
}

export default Footer
