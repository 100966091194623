import React from 'react'
import helpImage from '../../assets/helpimage.jpg'
import { IoMdCheckmark } from "react-icons/io";
import Appointment from './Appointment';

const HomeHelpSection = () => {
    return (
        <>
            <div className="container-fluid dark text-white my-6 mb-0" >
                <div className="row" >
                    <div className="col-lg-5 p-0" >
                        <img src={helpImage} className="img-help" />
                    </div>
                    <div className="col-lg-7 px-md-12 my-auto help-content" >
                        <h1 className=" mb-4" >How will we help you?</h1>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Align your data practices with relevant data protection laws and regulations.</p>
                        </div>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Implement security measures to prevent unauthorized access, data breaches, and leaks.</p>
                        </div>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Demonstrate your commitment to data privacy to customers, employees, and partners.</p>
                        </div>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Develop a robust data breach response plan to minimize damage in case of a security incident.</p>
                        </div>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Empower and train your staff at all organisational levels as guardians of data privacy.
                                </p>
                        </div>
                        <div className="d-flex mb-3 align-items-center" >
                            <div className="check-bg my-auto mx-2 " ><IoMdCheckmark className='check-icon' /></div><p className=" m-0" >Facilitate data privacy change management.</p>
                        </div>
                    </div>
                </div>

            </div>
            <Appointment />
        </>
    )
}

export default HomeHelpSection
