import React, { useState } from 'react'
import OtherService from './OtherService';
import DataProcessed from './DataProcessed';
import ServicePackage from './ServicePackage';
import { Link } from 'react-router-dom';

const ServicePage = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div>

            {/* <div className="background-service">
                <div className="layer-aboutus text-center">
                    <div className="" >
                        <h1 className='fs5-3 accent' >Services</h1>
                        <h4 className='text-white' >INTIAL DATA-PRIVACY PROGRAM IMPLEMENTATION (‘IDPI’)</h4>
                        <p className="text-white px-4" >The Service Packages offered are in keeping with, and for the operationalization of your organization’s data privacy program in accordance with the Data Protection Act, 2020</p>
                    </div>
                </div>
            </div> */}

            <div className="background-service-home">
                <h1 className="text-center container-xxl mb-0 f4rem service_slider_head">Initial Data Privacy Programme Implementation</h1>
            </div>
            <div className='container py-5'>
                <div className="row">
                    <div className="col-md-8 mb-4">
                        <h2 class="accent font mb-3">Initial Data Privacy Programme Implementation  </h2>
                        <p className='pe-lg-5 text_justify'>The initial implementation of a data privacy programme marks a crucial step towards safeguarding personal and sensitive information within an organization. This phase involves the establishment of comprehensive policies and procedures aligned with legal standards and best practices in data protection. Key activities include conducting a thorough data inventory to understand what data is collected, stored, and processed, and assessing the risks associated with these activities.</p>
                        <p className='pe-lg-5 text_justify'> Training and awareness programmes are rolled out to ensure all employees understand their roles in protecting data privacy. Additionally, technical and organizational measures are put in place to prevent unauthorized access, data breaches, and ensure the integrity and confidentiality of data. This foundational stage sets the groundwork for a culture of privacy that respects individual rights and complies with regulatory requirements, thereby building trust with clients, customers, and stakeholders.</p>
                    </div>
                    <div className='col-md-4 mb-4'>
                        <div className='card card_shodow1 border-0'>
                            <h5 className='card-header bg_data_ul py-3 '>Data Privacy Services</h5>
                            <div className='card-body'>
                                <ul className='mb-0'>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-officer' className='clr_data_list'>The Outsourced Data Protection Officer services </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-advisory' className='clr_data_list'>The General Support, Consulting and Advisory Service </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-process' className='clr_data_list'>Data Privacy Process Re-engineering and Alignment </Link></li>
                                    <li className='f14 mb-1 marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-security' className='clr_data_list'>The Security and Technical Privacy Services </Link></li>
                                    <li className='f14  marker_data' onClick={() => { handleClose(); scrollToTop() }}><Link to='/services-organisational' className='clr_data_list'>Organisational and Executive Privacy Training Development </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className='container-lg container-md-fluid border border-top-light' >
                <ServicePackage />
            </div> */}
            {/* <div className='container-fluid' >
                <div className='row mt-5' >
                    <div className='col-lg-7 d-flex flex-column align-items-center' >
                        <OtherService />
                    </div>
                    <div className='col-lg-5 ' >
                        <DataProcessed />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ServicePage

