import React from 'react'
import { GrStorage } from "react-icons/gr";
import { GoLaw } from "react-icons/go";
import { IoIosLock } from "react-icons/io";
import { TbTargetArrow } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import { BsSafe2 } from "react-icons/bs";
import { MdOutlineChecklist } from "react-icons/md";
import { GrSystem } from "react-icons/gr";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import PrincipleModal from './PrincipleModal';
import { Link } from 'react-router-dom';

const PrincipleDPA = () => {
    return (
       <div>
         <div className="container my-6" >
            <h1 className="font text-center" >Our work ensures compliance with the 8 standards of the DPA</h1>
            <div className="d-flex flex-wrap my-5 justify-content-lg-between justify-content-between justify-content-md-center gap-3" >
                <Link className="principle_card  text-center" data-bs-toggle="modal" data-bs-target="#modalFairness">
                    <div className="principle-icon mx-auto my-3" >
                        <GoLaw className='icon' />
                    </div>
                    <h6 className='font_h6'>Fairness and Lawfulness</h6>
                </Link>
                <Link className="principle_card  text-center" data-bs-toggle="modal" data-bs-target="#modalPurpose">
                    <div className="principle-icon mx-auto my-3" >
                        <TbTargetArrow className='icon' />
                    </div>
                    <h6 className='font_h6'>Purpose and Transparency</h6>
                </Link>
                <Link className="principle_card text-center" data-bs-toggle="modal" data-bs-target="#modalMinimization">
                    <div className="principle-icon mx-auto my-3" >
                        <GrStorage className='icon' />
                    </div>
                    <h6 className='font_h6'>Data Minimization</h6>
                </Link>
                <Link className="principle_card text-center" data-bs-toggle="modal" data-bs-target="#modalAccuracy">
                    <div className="principle-icon mx-auto my-3" >
                        <MdOutlineChecklist className='icon' />
                    </div>
                    <h6 className='font_h6'>Accuracy</h6>
                </Link>

                <Link className="principle_card  text-center" data-bs-toggle="modal" data-bs-target="#modalStorage">
                    <div className="principle-icon mx-auto my-3" >
                        <BsSafe2 className='icon' />
                    </div>
                    <h6 className='font_h6'>Storage Limitation</h6>
                </Link>
                <Link className="principle_card  text-center" data-bs-toggle="modal" data-bs-target="#modalRights">
                    <div className="principle-icon mx-auto my-3" >
                        <IoIosLock className='icon' />
                    </div>
                    <h6 className='font_h6'>Rights of the Data Subject</h6>
                </Link>

                <Link className="principle_card text-center" data-bs-toggle="modal" data-bs-target="#modalImplementation">
                    <div className="principle-icon mx-auto my-3" >
                        <GrSystem className='icon' />
                    </div>
                    <h6 className='font_h6'>Implementation of Technical and Organizational Measures</h6>
                </Link>
                
               
                <Link className="principle_card text-center" data-bs-toggle="modal" data-bs-target="#modalCross">
                    <div className="principle-icon mx-auto my-3" >
                        <FaArrowRightArrowLeft className='icon' />
                    </div>
                    <h6 className='font_h6'> Cross-Border Transfers</h6>
                </Link>

            </div>
        </div>
        <PrincipleModal />
       </div>
        
    )
}

export default PrincipleDPA
