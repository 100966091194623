import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { route } from './routes'



function App() {
  return (
    <Router basename='' >
      <Navbar />
      <Routes>
        {route.map((route) => {
          return <Route path={route.path} element={route.component} />
        })}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
