import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'

const Appointment = () => {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const menuRef = useRef(null)

    const toggleMenu = () => {
        setShow(!show)
    }

    const handleClose = () => {
        setShow(false)
    }

    const searchOpen = () => {
        setOpen(true)
    }

    const searchClose = () => {
        setOpen(false)
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='appointment_bg'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='appointment-box gap-2' >
                            <h4 className='m-0 font' >United Consulting International Limited<br />
                                We protect, educate and guide businesses for enduring excellence
                            </h4>
                            <div>
                                <Link onClick={() => { handleClose(); scrollToTop() }} to='/appointment' type="button" class="btn font btn-dark">Make Appointment</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appointment
