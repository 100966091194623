import React, { useState } from 'react';
import ImageSection from './ImageSection'
import HomeHelpSection from './HomeHelpSection'
import OurTeam from '../ourteam/OurTeam'
import ServiceCard from './ServiceCard'
import PrincipleDPA from './PrincipleDPA'
import NavbarTwo from './NavbarTwo'
import VideoSection from './VideoSection'
import { Link } from 'react-router-dom'

import Add from './Add';
const Home = () => {
  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const toggleMenu = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  return (

    <div>
      <ImageSection />
      <PrincipleDPA />
      <HomeHelpSection />
      <ServiceCard />
      <VideoSection />
      <OurTeam />
      <Add />
    </div>
  )
}

export default Home
