import React from 'react'
import team1 from '../../assets/uci-owner.png'
import team2 from '../../assets/our_team1.png'
import team3 from '../../assets/our_team2.png'
import team4 from '../../assets/our_team3.png'
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from 'react-router-dom';


const OurTeam = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
      }


    return (
        <div className="container my-6" >
            <h1 className="font text-center" >Our Team</h1>
            <p className="text-secondary text-center" >Our team collectively has over 30 years' experience in data privacy and protection, corporate compliance, law, international development, business consulting, finance, and marketing. We have therefore established the credibility of you entrusting your business in the hands of experienced and competent professionals.
            </p>
            <div className="row mt-5" >
                <div className="col-md-6 col-lg-3" >
                    <div className="text-center " >
                        <div class="img-hover-zoom box img-hover-zoom--colorize mx-auto box_div">
                            <img src={team1} className="img-fluid team-img" alt="our team image" />
                            <button onClick={scrollToTop} className="button" ><Link to='/know-our-team' className='text-white' >Learn More</Link></button>
                        </div>
                        <div className="mt-3" >
                            <h4 className="m-0" >Najuequa Barnes</h4>
                            <p className="text-secondary" >Chief Privacy Officer/Managing Director</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" >
                    <div className="text-center" >
                        <div class="img-hover-zoom box img-hover-zoom--colorize mx-auto box_div">
                            <img src={team2} className="img-fluid team-img" alt="our team image" />
                            <button onClick={scrollToTop} className="button" ><Link to='/know-our-team' className='text-white' >Learn More</Link></button>
                        </div>
                        <div className="mt-3" >
                            <h4 className="m-0" >Cleveland Barnes</h4>
                            <p className="text-secondary" >Advisory & Risk Assessment Director </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" >
                    <div className="text-center" >
                        <div class="img-hover-zoom box img-hover-zoom--colorize mx-auto box_div">
                            <img src={team3} className="img-fluid team-img" alt="our team image" />
                            <button onClick={scrollToTop} className="button" ><Link to='/know-our-team' className='text-white' >Learn More</Link></button>
                        </div>
                        <div className="mt-3" >
                            <h4 className="m-0" >Stephen Barnes </h4>
                            <p className="text-secondary" >Director of Governance and Finance  </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3" >
                    <div className="text-center" >
                        <div class="img-hover-zoom box img-hover-zoom--colorize mx-auto box_div">
                            <img src={team4} className="img-fluid team-img" alt="our team image" />
                            <button onClick={scrollToTop} className="button" ><Link to='/know-our-team' className='text-white' >Learn More</Link></button>
                        </div>
                        <div className="mt-3" >
                            <h4 className="m-0" >Rushane Barnes </h4>
                            <p className="text-secondary" >Director of Information and Technology </p>
                        </div>
                    </div>
                </div>
            </div>
            <Link onClick={scrollToTop} to='/know-our-team' className="text-decoration-none text-dark" > <h4 className="text-center mt-3" >Know our team  <AiOutlineArrowRight /></h4></Link>
        </div>
    )
}

export default OurTeam
