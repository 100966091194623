import React, { useRef, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { RxCross1, RxHamburgerMenu } from "react-icons/rx";
import { IoMdArrowDropdown, IoMdClose } from "react-icons/io";
// import logo from '../assets/UCI-white.png';
import logo from '../assets/uci-logo-main.png';
import { CiSearch } from "react-icons/ci";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import NavbarTwo from './HomePage/NavbarTwo';

const Navbar = () => {
    const [show, setShow] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const menuRef = useRef(null);
    const searchRef = useRef(null);

    const toggleMenu = () => {
        setShow(!show);
    };

    const handleClose = () => {
        setShow(false);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        window.addEventListener('scroll', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearch(false);
        }
    };

    const handleScroll = () => {
        setShowSearch(false);
    };

    return (
        <>
            <nav>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <NavLink to="/">
                                    <img className='company-logo ms-0' src={logo} alt="Company Logo" />
                                </NavLink>
                                <div>
                                    <ul id='navbar' className={show ? '#navbar active' : "#navbar"}>
                                        <div onClick={toggleMenu} className='cross-icon'>
                                            <IoMdClose />
                                        </div>
                                        <li className="search-tab">
                                            <div className='input-box open'>
                                                <input type="text" placeholder="Search..." />
                                                <span className="search">
                                                    <CiSearch onClick={toggleSearch} className="uil uil-search search-icon" />
                                                </span>
                                            </div>
                                        </li>
                                        <li onClick={() => { handleClose(); scrollToTop() }} className='active' ><NavLink to='/'>Home</NavLink></li>
                                        <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/aboutUs'>About us</NavLink> </li>
                                        <li className='' onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/allblog'>Blog</NavLink></li>
                                        <li className='' onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/gallery'>Gallery</NavLink></li>
                                        <li onClick={() => { handleClose(); scrollToTop() }} className='' ><NavLink to='/overview'>Organisational Overview</NavLink></li>
                                        <li className='hide-cls_nav'><NavLink  >Data Privacy Services<IoMdArrowDropdown className='fs10' /></NavLink>
                                            <ul className='sub-menu'  >
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services' >INTIAL DATA-PRIVACY PROGRAM IMPLEMENTATION (IDPI)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-officer'>THE OUTSOURCED DATA PROTECTION OFFICER SERVICE (ODPO)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-advisory'> THE GENERAL SUPPORT, CONSULTING AND ADVISORY SERVICE (GSCA)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-process'> DATA PRIVACY PROCESS RE-ENGINEERING & ALIGNMENT (DPRA)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-security'>THE CYBER SECURITY & TECHNICAL PRIVACY SERVICES (CSTP)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/services-organisational'>ORGANIZATIONAL & EXECUTIVE PRIVACY TRAINING & DEVELOPMENT (OPTD)</NavLink></li>

                                            </ul>
                                        </li>
                                        <li className='hide-cls_nav' onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/Corporate-Compliance'>Corporate Compliance & Business Consultation</NavLink></li>
                                        <li className='hide-cls_nav' onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/ifrs-services'> Actuarial & IFRS9 Services</NavLink></li>
                                        {/* <li className='hide-cls_nav'> Actuarial & IFRS Services<IoMdArrowDropdown className='fs10' />
                            <ul className='sub-menu'  >
                                <li className='li_hover_mobile'>
                                    <h6 className='d-flex gap-2'><span>1.</span> <span>Group and Health insurance</span></h6>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Group Life & AD&D</span></NavLink></div>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Personal Accident (PA) insurance</span></NavLink></div>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Individual Health (IH) insurance</span></NavLink></div>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Group Health (GH) insuranc</span></NavLink></div>

                                </li>
                                <li className='li_hover_mobile'>
                                    <h6 className='d-flex gap-2'><span>2.</span> <span>IFRS 9</span></h6>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>IFRS 9 Loan</span></NavLink></div>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>IRS 9 Investments</span></NavLink></div>

                                </li>
                                <li className='li_hover_mobile'>
                                    <h6 className='d-flex gap-2'><span>3.</span> <span>Credit Risk Management (CRM)</span></h6>
                                    <div className='mb-1'><NavLink to='/' className='d-flex' onClick={() => { handleClose(); scrollToTop() }}><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Credit scorecard development and implementation</span></NavLink></div>
                                    <div className='mb-1'><div className='d-flex'><span><MdOutlineKeyboardDoubleArrowRight className='fs-5' /></span><span>Risk based pricing (RBP) development and implementation</span></div>
                                        <ul className='sub2-menu'>
                                            <li className='border-0 li_hover_mobile' onClick={() => { handleClose(); scrollToTop() }}><Link className=''>Allocate Expenses % of loan balance</Link></li>
                                            <li className='border-0 li_hover_mobile' onClick={() => { handleClose(); scrollToTop() }}><Link className=''>Allocate Unit Cost by expenses and by risk class for each loan type</Link></li>
                                            <li className='border-0 li_hover_mobile' onClick={() => { handleClose(); scrollToTop() }}><Link className=''>Derive interest rate by risk class for each loan type</Link></li>
                                        </ul>
                                    </div>

                                </li>

                            </ul>
                        </li> */}


                                        <li className='hide-cls_nav'><NavLink  >Industries<IoMdArrowDropdown className='fs10' /></NavLink>
                                            <ul className='sub-menu'  >
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry'>Education Industry </NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-medical'>Medical Industry</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-financial'> Financial Industry </NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-charities'> Charities/Churches (Non-profit Organizations)</NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-hotel'> Hotel and Tourism Industry </NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-Public' > Public Sector Entities </NavLink></li>
                                                <li onClick={() => { handleClose(); scrollToTop() }} ><NavLink to='/industry-private' > Private Sector Organisations </NavLink></li>
                                            </ul>
                                        </li>
                                        <li onClick={() => { handleClose(); scrollToTop() }}><NavLink to='/contactUs' >Contact us</NavLink></li>
                                        <li className='d-none pe-0 d-lg-block'>
                                            <span className='bg_light '>
                                                {showSearch ? (
                                                    <RxCross1 onClick={toggleSearch} className='text-dark' />
                                                ) : (
                                                    <FaSearch onClick={toggleSearch} className='search_click text-dark' />
                                                )}
                                                {showSearch && (
                                                    <div className={`search-box`} ref={searchRef}>
                                                        <FaSearch />
                                                        <input type="text" className='input_search' placeholder="Search here..." />
                                                    </div>
                                                )}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div id='mobile' onClick={toggleMenu}>
                                    <span>
                                        <RxHamburgerMenu />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <NavbarTwo />

        </>
    );
}

export default Navbar;
