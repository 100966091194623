import React from 'react'
import { MdOutlinePrivacyTip } from "react-icons/md";
import { RiRotateLockLine } from "react-icons/ri";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { MdOutlinePermDataSetting } from "react-icons/md";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { FaBusinessTime } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { TbVirusSearch } from "react-icons/tb";




const ServiceCard = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className='container my-6' >
            <div className='mb-4'>
                <h1 className='text-center font' >Our Services</h1>
                <p className='text-secondary text-center' >The Service Packages offered are in keeping with, and for the operationalization of your organization’s data privacy program in accordance with the Data Protection Act, 2020:</p>
            </div>
            <div className='row p-0 pt-3 ' >
                {/* <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                    <div className='service-card-border py-3 p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <RiRotateLockLine className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >IDPI</h4>
                            <p> INTIAL DATA-PRIVACY PROGRAM IMPLEMENTATION</p>
                            <Link to='/services' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center' >
                    <div className='service-card-border p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <MdOutlinePrivacyTip className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >ODPO</h4>
                            <p>THE OUTSOURCED DATA PROTECTION OFFICER SERVICE </p>
                            <Link to='/services-officer' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center' >
                    <div className='service-card-border p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <LiaHandsHelpingSolid className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >GSCA</h4>
                            <p> THE GENERAL SUPPORT, CONSULTING AND ADVISORY SERVICE </p>
                            <Link to='/services-advisory' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center' >
                    <div className='service-card-border p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <MdOutlinePermDataSetting className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >DPRA</h4>
                            <p>DATA PRIVACY PROCESS RE-ENGINEERING & ALIGNMENT  </p>
                            <Link to='/services-process' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center' >
                    <div className='service-card-border p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <HiOutlineDesktopComputer className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >CSTP</h4>
                            <p>THE CYBER SECURITY & TECHNICAL PRIVACY SERVICES</p>
                            <Link to='/services-security' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center' >
                    <div className='service-card-border p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <GrCertificate className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >OPTD</h4>
                            <p>ORGANIZATIONAL & EXECUTIVE PRIVACY TRAINING & DEVELOPMENT </p>
                            <Link to='/services-organisational' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div> */}
                <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                    <div className='service-card-border py-3 p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <RiRotateLockLine className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >Data Privacy</h4>
                            <p className='p3_line'> Data privacy services protect sensitive information and ensure compliance.</p>
                            <Link to='/services' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                    <div className='service-card-border py-3 p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <FaBusinessTime className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >Corporate Compliance and Business Consultation</h4>
                            <p className='p3_line'>United Consulting International Limited offers a comprehensive suite of services aimed at ensuring corporate compliance and providing valuable business consultation.</p>
                            <Link to='/Corporate-Compliance' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 col-lg-4 mb-4 text-center ' >
                    <div className='service-card-border py-3 p-3 h-100' >
                        <div className="service-card-icon mx-auto my-3" >
                            <TbVirusSearch  className='icon' />
                        </div>
                        <div >
                            <h4 className='font' >Actuarial and IFRS Services </h4>
                            <p className='p3_line'>Our Actuarial & IFRS9 Services are tailored to support organizations in navigating the complexities of financial reporting and risk management.</p>
                            <Link to='/ifrs-services' onClick={scrollToTop} ><button type="button" class="btn py-1 btn-outline-warning rounded-1 mb-3">Learn more</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard
